import { STATUS_NAME_CODES } from "../../constants/GlobalConstants";
import notificationTypes from "../../constants/UIElementNotificationReferences.json";
import notificationFunctionCodes from "../../constants/NotificationFunctionCodes.json";
import { trim } from "lodash";
import validation from "../../constants/validation";

/** The notifications array returned from the back-end inside the declaration object is an array of objects.
 * Each object consists of information about the notification itself (functionCode, and other data that would be displayed to the user)
 */
export const parseNotifications = (notifications) => {
	let allNotifications = [];

	notifications.forEach((notification) => {
		const parsedNotification = parseNotificationObject(notification);

		if (parsedNotification) {
			allNotifications.push(parsedNotification);
		}
	});

	allNotifications.sort((a, b) => (a.processedTime > b.processedTime ? -1 : 1));

	return allNotifications;
};

export const getNotificationEntityType = (notification) => {
	if (notification.additionalMessage) {
		return notification.additionalMessage.type;
	}

	return "declaration";
};

/**
 * Each object in the "uiElements" array that's returned from the back-end consists of a "validationCode"
 * which should match the "referenceId" of one of the entries in the "UIElementNotificationReferences.json" file,
 * and "tagIDJsonPath" which is an array of elements which will be used to fill the {{element}} placeholders in the "message"
 * property of the array items in "UIElementNotificationReferences.json"
 */
export const parseNotificationErrors = (errors) => {
	let parsedErrors = [];

	errors.forEach((notification) => {
		let error = {
			level: "header",
			elements: [],
		};

		notification.elements?.forEach((element) => {
			const splitPath = element.split(".");
			const elementName = splitPath[splitPath.length - 1].split("[")[0];

			const validationElement = validation.find(
				(elem) => elementName === elem.elementName || elem.additionalIds?.includes(elementName)
			);

			if (validationElement) {
				error.elements.push(validationElement);
			}

			const declarationItemsPath = splitPath.find((entry) => entry.startsWith("declarationItems"));

			if (declarationItemsPath) {
				error = {
					...error,
					level: "item",
					itemIndex: parseInt(declarationItemsPath?.split("[")[1]?.split("]")[0] || 0),
				};
			}
		});

		if (error.elements.length) {
			const notificationType = notificationTypes.find(
				(type) => type.referenceId === notification.validationCode
			);

			if (notificationType) {
				const { elements } = error;

				let message = notificationType.message
					.replace("{{element}}", `**${trim(elements[0]?.textDescription)}**`)
					.replace("{{element1}}", `**${trim(elements[0]?.textDescription)}**`)
					.replace("{{element2}}", `**${trim(elements[1]?.textDescription)}**`);

				if (error.level === "item") {
					message = `**Item #${error.itemIndex + 1}**: ${message}`;
				}

				parsedErrors.push({
					type: "validationError",
					message,
					payload: error,
				});
			}
		} else {
			console.log(notification);
		}
	});

	return parsedErrors;
};

/** Takes a notification object that's returned from the API, generate the notification message
 * using the "functionCode" from the "NotificationFunctionCodes.json" file, and adds the "displayElements" will be displayed
 * when the "View" button next to the notification is clicked
 */
export const parseNotificationObject = (notification) => {
	const { data } = notification;
	let result = {
		_id: notification._id,
		processedTime: data.processedTime,
		declaration: notification.declaration,
		isRead: notification.isRead,
		errors: notification.data.errors ? parseNotificationErrors(notification.data.errors) : [],
	};

	const entityType = getNotificationEntityType(notification);

	const variablesRegex = /{{(.*)}}/g;

	let message = notificationFunctionCodes[data.functionCode]?.[entityType];

	if (message) {
		const variables = message.match(variablesRegex);

		if (variables?.length) {
			variables.forEach((variable) => {
				variable = variable.replace("{{", "").replace("}}", "");
				let value = "denied";

				if (
					variable === "status" &&
					data.functionCode === 11 &&
					data.statuses.find(({ nameCode }) => STATUS_NAME_CODES[nameCode] === "accepted")
				) {
					value = "accepted";
				}

				message = message.replace(`{{${variable}}}`, value);
			});
		}

		result = { ...result, message };
	}

	/** Elements that exist on all notification that wouldn't be of interest to the user */
	let excludedElements = ["declarationId", "functionalReferenceIdOfDeclaration", "versionId"];

	/** Display elements are elements that will be displayed to the user when the "view" button
	 * next to the notification is clicked. Only string, number or array elements are extracted from the
	 * "declaration" object
	 */
	const getDisplayElementsFromObject = (object) => {
		Object.keys(object).forEach((key) => {
			const value = object[key];
			const isArrayOfObjects = Array.isArray(value) && value.length && typeof value[0] === "object";

			if (
				(isArrayOfObjects || typeof value === "string" || typeof value === "number") &&
				!excludedElements.includes(key)
			) {
				result.elements = { ...(result.elements || {}), [key]: value };
			} else if (typeof value === "object") {
				getDisplayElementsFromObject(value);
			}
		});
	};

	if (data.declaration) {
		getDisplayElementsFromObject(data.declaration);
	}

	return result;
};
