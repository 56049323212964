import { useContext, useRef, useState } from "react";
import Button from "../../../common/Button";
import { PiDotsThreeVertical } from "react-icons/pi";
import Popover from "../../../common/Popover/Popover";
import { useTranslation } from "react-i18next";
import PopoverOption from "../../../common/Popover/PopoverOption";
import DeclarationsContext from "../../../../context/declarations/DeclarationsContext";
import DeclarationDetailsModal from "../../../modals/DeclarationDetailsModal/DeclarationDetailsModal";
import { removeQueryParam, updateQueryParam } from "../../../../router/utils";
import { DECLARATION_ID_QUERY_PARAM, ELEMENT_QUERY_PARAM } from "../../../../constants/GlobalConstants";
import ConfirmationModal from "../../../modals/ConfirmationModal";
import NotificationToast from "../../../common/NotificationToast";
import Badge from "../../../common/Badge";
import { getDeclarationLabel } from "../../../../helpers";

const DeclarationActionsPopover = () => {
	const [loadingAction, setLoadingAction] = useState();
	const [isOpen, setIsOpen] = useState(false);
	const { t } = useTranslation();
	const targetRef = useRef(null);
	const [isDecDetailsModalOpen, setIsDecDetailsModalOpen] = useState(false);
	const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
	const [toast, setToast] = useState("");

	const {
		state,
		createDeclaration,
		// getValidationErrors,
		deleteDeclaration,
		updateDeclaration,
		isDeclarationReadOnly,
		clearSelectedDeclaration,
		setHasUnsavedChanges,
		getDeclarationById,
		setSelectedDeclaration,
	} = useContext(DeclarationsContext);

	const handleSaveClick = async () => {
		if (loadingAction) {
			return;
		}

		setLoadingAction("save");

		updateQueryParam(ELEMENT_QUERY_PARAM, "");

		await updateDeclaration(state.declaration._id, { data: state.declaration.data });

		setLoadingAction(null);
		setToast(t("declarationSaveSuccess"));
		setIsOpen(false);

		setHasUnsavedChanges(false);
	};

	const handleDuplicateClick = async () => {
		if (loadingAction) {
			return;
		}

		setLoadingAction("duplicate");

		const createdDeclaration = await createDeclaration({
			data: state.declaration.data,
			description: `Duplicate of ${getDeclarationLabel(state.declaration)}`,
		});

		setLoadingAction(null);
		setIsOpen(false);

		updateQueryParam(DECLARATION_ID_QUERY_PARAM, createdDeclaration._id);
	};

	const handleReloadClick = async () => {
		setIsOpen(false);

		const declaration = await getDeclarationById(state.declaration._id, { forceReload: true });
		setSelectedDeclaration(declaration);
	};

	const handleDeleteClick = async () => {
		if (loadingAction) {
			return;
		}

		setLoadingAction("delete");

		await deleteDeclaration(state.declaration._id);

		setLoadingAction(null);
		setIsDeleteConfirmModalOpen(false);

		removeQueryParam(DECLARATION_ID_QUERY_PARAM);
		clearSelectedDeclaration();
		setIsOpen(false);
	};

	return (
		<>
			<NotificationToast
				isOpen={Boolean(toast)}
				onClose={() => setToast("")}
				text={toast}
				variant="success"
			/>

			<DeclarationDetailsModal
				isOpen={isDecDetailsModalOpen}
				onClose={() => setIsDecDetailsModalOpen(false)}
			/>

			<ConfirmationModal
				isOpen={isDeleteConfirmModalOpen}
				title={t("areYouSure")}
				description={t("deleteDeclarationConfirm")}
				primaryActionLabel={t("deleteDeclaration")}
				isActionLoading={loadingAction === "delete"}
				onPrimaryActionClick={handleDeleteClick}
				onClose={() => setIsDeleteConfirmModalOpen(false)}
			/>

			<Popover
				style={{ paddingRight: 0, paddingLeft: 0, paddingTop: 8, paddingBottom: 8 }}
				isOpen={isOpen}
				targetRef={targetRef}
				onClose={() => setIsOpen(false)}
			>
				<PopoverOption onClick={handleReloadClick}>{t("reload")}</PopoverOption>
				<PopoverOption
					isDisabled={isDeclarationReadOnly()}
					isLoading={loadingAction === "save"}
					onClick={handleSaveClick}
				>
					<Badge
						style={{ position: "static", marginRight: 8 }}
						isCompact
						isHidden={!state.hasUnsavedChanges}
					>
						<div></div>
					</Badge>

					{t("saveChanges")}
				</PopoverOption>
				<PopoverOption onClick={handleDuplicateClick} isLoading={loadingAction === "duplicate"}>
					{t("duplicateDeclaration")}
				</PopoverOption>
				<PopoverOption
					isDisabled={!state.declaration.mrn || state.declaration.status !== "cleared"}
					onClick={() => {
						setIsDecDetailsModalOpen(true);
						setIsOpen(false);
					}}
				>
					{t("viewDeclarationDetails")}
				</PopoverOption>
				<PopoverOption
					onClick={() => {
						setIsDeleteConfirmModalOpen(true);
						setIsOpen(false);
					}}
				>
					{t("deleteDeclaration")}
				</PopoverOption>
			</Popover>

			<Badge
				style={{ top: 4, right: 4, width: 10, height: 10 }}
				isCompact
				isHidden={!state.hasUnsavedChanges}
			>
				<div ref={targetRef}>
					<Button type="icon" onClick={() => setIsOpen(true)}>
						<PiDotsThreeVertical />
					</Button>
				</div>
			</Badge>
		</>
	);
};

export default DeclarationActionsPopover;
