import React, { useMemo } from "react";
import { Toast } from "react-bootstrap";

const NotificationToast = ({ isOpen, onClose, text, title, variant }) => {
	const colors = useMemo(() => {
		if (variant === "success") {
			return {
				backgroundColor: "rgb(25,135,84)",
				color: "white",
			};
		} else if (variant === "error") {
			return {
				backgroundColor: '#510b0b',
				color: 'white'
			}
		} else {
			return {
				backgroundColor: "#fff",
				color: "black",
			};
		}
	}, [variant]);

	return (
		<Toast
			autohide
			style={{
				position: "fixed",
				top: 16,
				right: 16,
				zIndex: 999,
				...colors,
			}}
			onClose={onClose}
			show={isOpen}
			delay={3000}
		>
			{title && (
				<Toast.Header closeButton={false}>
					<strong className="mr-auto">{title}</strong>
				</Toast.Header>
			)}
			<Toast.Body style={{ textAlign: "left" }}>{text}</Toast.Body>
		</Toast>
	);
};

export default NotificationToast;
