import React, { useContext, useReducer, useState } from "react";
import DeclarationsContext from "./DeclarationsContext";
import declarationsReducer from "./DeclarationsReducer";
import {
	SET_ITEM_ELEMENT_VALUE,
	SET_ELEMENT_VALUE,
	CREATE_ITEM,
	DELETE_ITEM,
	UPDATE_STATE,
	UPDATE_ITEM,
	SET_ELEMENT_VALUE_IN_SETCION,
	SET_ITEM_ELEMENT_VALUE_IN_SECTION,
	SET_UNSAVED_CHANGES,
	LIST_DECLARATIONS_LOADING,
	LIST_DECLARATIONS,
	CLEAR_SELECTED_DECLARATION_STATE,
	SET_LOADING_DECLARATION_ID,
} from "../types";
import headerObligations from "../../constants/obligations/header-obligations";
import itemsObligations from "../../constants/obligations/items-obligations";
import { useHistory } from "react-router";
import { findElementObligationsUtil, getValidationErrorsUtil } from "./validations";
import {
	fetchDeclarations as fetchDeclarationsApiReq,
	updateDeclaration as updateDeclarationApiReq,
	createDeclaration as createDeclarationApiReq,
	deleteDeclaration as deleteDeclarationApiReq,
	submitDeclaration as submitDeclarationApiReq,
	cancelDeclaration as cancelDeclarationApiReq,
	getDeclarationById as getDeclarationByIdApiReq,
} from "../../api/declarations";
import { getDeclarationLabel } from "../../helpers";
import { updateQueryParam } from "../../router/utils";
import { DECLARATION_ID_QUERY_PARAM, DECLARATION_STATUSES } from "../../constants/GlobalConstants";
import WorkspacesContext from "../workspaces/WorkspacesContext";
import NotificationToast from "../../components/common/NotificationToast";
import { useTranslation } from "react-i18next";
import { parseNotifications } from "../notifications/utils";

export const initialState = {
	declaration: null,
	notifications: [],
	mandatoryElements: [],
	disabledElements: [],
	validationErrors: [],
	mandatoryPulldownOptions: {},
	hasUnsavedChanges: false,
	declarations: [],
	declarationsLoading: false,
	loadingDeclarationId: false,
};

const DeclarationsState = (props) => {
	const [toast, setToast] = useState({ isOpen: false, title: "", text: "" });
	const { selectedWorkspaceId } = useContext(WorkspacesContext);
	const [state, dispatch] = useReducer(declarationsReducer, initialState);
	const { t } = useTranslation();
	const history = useHistory();

	const setLoadingDeclarationId = (id) => {
		dispatch({
			type: SET_LOADING_DECLARATION_ID,
			payload: id,
		});
	};

	const setDeclarations = (declarations) => {
		dispatch({
			type: LIST_DECLARATIONS,
			payload: declarations,
		});
	};

	const updateDeclarationInState = (id, updates) => {
		const declarationIndex = state.declarations.find((declaration) => declaration._id === id);

		if (declarationIndex > -1) {
			state.declarations[declarationIndex] = { ...state.declarations[declarationIndex], ...updates };

			setDeclarations([...state.declarations]);
		}

		if (state.declaration._id === id) {
			setSelectedDeclaration({ ...state.declaration, ...updates });
		}
	};

	const listDeclarations = async () => {
		dispatch({
			type: LIST_DECLARATIONS_LOADING,
			payload: true,
		});

		const result = await fetchDeclarationsApiReq(selectedWorkspaceId);

		setDeclarations(result);
		setHasUnsavedChanges(false);
	};

	const getDeclarationById = async (id, options) => {
		const declarationIndex = state.declarations.findIndex((dec) => dec._id === id);

		if (state.declarations[declarationIndex]?.isFullDataLoaded && !options.forceReload) {
			return state.declarations[declarationIndex];
		}

		setLoadingDeclarationId(id);

		try {
			let declaration = await getDeclarationByIdApiReq(id);

			declaration = { ...declaration, isFullDataLoaded: true };

			if (declarationIndex > -1) {
				state.declarations[declarationIndex] = declaration;
				setDeclarations([...state.declarations]);
			}

			setLoadingDeclarationId(null);

			return { ...declaration, notifications: parseNotifications(declaration.notifications) };
		} catch (e) {
			setLoadingDeclarationId(null);

			throw e;
		}
	};

	const setSelectedDeclaration = (declaration) => {
		dispatch({
			type: UPDATE_STATE,
			payload: declaration,
		});
	};

	const clearSelectedDeclaration = () => {
		dispatch({
			type: CLEAR_SELECTED_DECLARATION_STATE,
		});
	};

	const createDeclaration = async (payload, options) => {
		try {
			const newDeclaration = await createDeclarationApiReq({
				...payload,
				workspace: selectedWorkspaceId,
			});

			state.declarations = [...state.declarations, newDeclaration];

			if (!options?.avoidRerender) {
				setDeclarations([...state.declarations]);
			}

			updateQueryParam(DECLARATION_ID_QUERY_PARAM, newDeclaration._id);

			return newDeclaration;
		} catch (e) {
			const { status } = e.response;

			if (status === 429) {
				setToast({
					isOpen: true,
					title: t("errorOccured"),
					text: t("declarationsLimitReached"),
					variant: "error",
				});
			} else {
				throw e;
			}
		}
	};

	const updateDeclaration = async (declarationId, payload) => {
		let declarationIndex = state.declarations.findIndex((declaration) => declaration._id === declarationId);

		if (declarationIndex < 0) {
			return;
		}

		const updatedDeclaration = await updateDeclarationApiReq(declarationId, payload);

		state.declarations[declarationIndex] = updatedDeclaration;

		setDeclarations([...state.declarations]);
	};

	const submitDeclaration = async (declaration) => {
		const { declaration: updatedDeclaration } = await submitDeclarationApiReq(declaration);

		let declarationIndex = state.declarations.findIndex(({ _id }) => declaration._id === _id);

		if (declarationIndex < 0 || !updatedDeclaration) {
			return;
		}

		state.declarations[declarationIndex] = updatedDeclaration;

		if (updatedDeclaration.isAmendment) {
			const parentDeclarationIndex = state.declarations.findIndex(
				(dec) => dec._id === updatedDeclaration.parentDeclaration
			);

			if (parentDeclarationIndex > -1) {
				state.declarations[parentDeclarationIndex] = {
					...state.declarations[parentDeclarationIndex],
					status: DECLARATION_STATUSES.SUBMITTED,
				};
			}
		}

		if (state.declaration?._id === declaration._id) {
			setSelectedDeclaration({ ...state.declaration, status: DECLARATION_STATUSES.SUBMITTED });
		}

		setDeclarations([...state.declarations]);
	};

	const cancelDeclaration = async (declarationId, cancelReason) => {
		let declarationIndex = state.declarations.findIndex(({ _id }) => declarationId === _id);

		if (declarationIndex < 0) {
			return;
		}

		await cancelDeclarationApiReq(declarationId, cancelReason);

		state.declarations[declarationIndex] = {
			...state.declarations[declarationIndex],
			status: DECLARATION_STATUSES.PENDING_CANCEL,
		};

		if (state.declaration?._id === declarationId) {
			setSelectedDeclaration({ ...state.declaration, status: DECLARATION_STATUSES.PENDING_CANCEL });
		}

		setDeclarations([...state.declarations]);
	};

	const deleteDeclaration = async (declarationId) => {
		await deleteDeclarationApiReq(declarationId);

		const declarationIndex = state.declarations.findIndex(({ _id }) => _id === declarationId);

		state.declarations.splice(declarationIndex, 1);

		setDeclarations([...state.declarations]);
	};

	const setElementValue = (name, value) => {
		dispatch({
			type: SET_ELEMENT_VALUE,
			payload: {
				name,
				value,
			},
		});
	};

	const setElementValueInSection = (section, name, value) => {
		dispatch({
			type: SET_ELEMENT_VALUE_IN_SETCION,
			payload: {
				name,
				value,
				section,
			},
		});
	};

	const setItemElementValue = (itemIndex, name, value) => {
		dispatch({
			type: SET_ITEM_ELEMENT_VALUE,
			payload: {
				itemIndex,
				name,
				value,
			},
		});
	};

	const setItemElementValueInSection = (itemIndex, section, name, value) => {
		dispatch({
			type: SET_ITEM_ELEMENT_VALUE_IN_SECTION,
			payload: {
				itemIndex,
				name,
				value,
				section,
			},
		});
	};

	const setHasUnsavedChanges = (hasUnsavedChanges) => {
		dispatch({
			type: SET_UNSAVED_CHANGES,
			payload: hasUnsavedChanges,
		});
	};

	const createItem = (name) => {
		dispatch({
			type: CREATE_ITEM,
			payload: {
				name,
				containerIdentificationNumber: "number(s) unknown",
				insertTimeStamp: new Date().toISOString(),
			},
		});
	};

	const updateItem = (itemIndex, updates) => {
		dispatch({
			type: UPDATE_ITEM,
			payload: {
				itemIndex,
				updates: {
					...updates,
					updateTimeStamp: new Date().toISOString(),
				},
			},
		});
	};

	const deleteItem = (itemIndex) => {
		dispatch({
			type: DELETE_ITEM,
			payload: { itemIndex },
		});
	};

	/** Recursively loop through nested objects in the DeclarationState to find the value of a provided key */
	const findValueInContext = (selectedKey, level) => {
		let value = null;

		const findValue = (object) => {
			if (object) {
				Object.keys(object).forEach((key) => {
					if (typeof object[key] === "object") {
						if (key === selectedKey) {
							value = object[key];
						} else if (!Array.isArray(object[key])) {
							findValue(object[key]);
						}
					} else {
						if (key === selectedKey) {
							value = object[key];
						}
					}
				});
			}
		};

		if (state.declaration) {
			const { declarationItems, ...headerElements } = state.declaration;

			if (level === "item") {
				findValue(declarationItems);
			} else if (level === "header") {
				findValue(headerElements);
			} else {
				findValue(state.declaration);
			}
		}
		return value;
	};

	/** Check if an element has been flagged as readOnly
	 * Currently elements will be set to be readOnly if they're specified in one of the obligiation files, in the "services" section
	 * with a value and not an empty string, if it has an empty string, then it will be set to be mandatory only by
	 * the findElementObligations action
	 */

	const findInServiceObligation = (obligations, elementName) => {
		let result = null;
		Object.keys(obligations).forEach((serviceKey) => {
			if (state.declaration.service === serviceKey) {
				Object.keys(obligations[serviceKey]).forEach((elementKey) => {
					if (obligations[serviceKey][elementKey] && elementKey === elementName) {
						result = obligations[serviceKey][elementKey];
					}
				});
			}
		});

		return result;
	};

	const isElementReadOnly = (elementName) => {
		let readOnly = false;

		if (state.declaration?.service) {
			// TODO: Update to work with the new tab query params
			if (history.location.pathname.startsWith("/items")) {
				readOnly = Boolean(findInServiceObligation(itemsObligations.services, elementName));
			} else {
				readOnly = Boolean(findInServiceObligation(headerObligations.services, elementName));
			}
		}

		return readOnly;
	};

	/** Returns read only array items of multiline table elements */
	const getElementObligation = (elementName) => {
		let element = null;

		if (state.declaration.service) {
			if (history.location.pathname.startsWith("/items")) {
				element = findInServiceObligation(itemsObligations.services, elementName);
			} else {
				element = findInServiceObligation(headerObligations.services, elementName);
			}
		}

		return element;
	};

	const getValidationErrors = () => {
		return getValidationErrorsUtil(state, dispatch, findValueInContext);
	};

	const findElementObligations = () => {
		return findElementObligationsUtil(state, dispatch, findValueInContext);
	};

	const printState = async () => {
		const json = JSON.stringify(state.declaration);
		const blob = new Blob([json], { type: "application/json" });
		const href = await URL.createObjectURL(blob);
		const link = document.createElement("a");

		link.href = href;
		link.download = "declaration.json";

		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const createAmendment = async (declaration) => {
		const amendment = await createDeclaration(
			{
				isAmendment: true,
				isFinalSupplementary: declaration.isFinalSupplementary,
				data: declaration.data,
				description: `Amendment for ${getDeclarationLabel(declaration)}`,
				parentDeclaration: declaration._id,
			},
			{ avoidRerender: true }
		);

		const declarationIndex = state.declarations.findIndex(({ _id }) => _id === declaration._id);

		if (declarationIndex > -1) {
			const declaration = state.declarations[declarationIndex];
			state.declarations[declarationIndex] = {
				...declaration,
				relatedDeclarations: [amendment, ...(declaration.relatedDeclarations || [])],
			};

			setDeclarations([...state.declarations]);
		}

		return amendment;
	};

	const isDeclarationReadOnly = () => {
		const status = state.declaration.status;

		return status !== DECLARATION_STATUSES.INCOMPLETE && status !== DECLARATION_STATUSES.REJECTED;
	};

	return (
		<DeclarationsContext.Provider
			value={{
				state: state,
				cancelDeclaration,
				createDeclaration,
				updateDeclaration,
				deleteDeclaration,
				setDeclarations,
				setHasUnsavedChanges,
				listDeclarations,
				setSelectedDeclaration,
				setElementValueInSection,
				setItemElementValueInSection,
				createItem,
				deleteItem,
				updateItem,
				setElementValue,
				setItemElementValue,
				findElementObligations,
				findValueInContext,
				getElementObligation,
				isElementReadOnly,
				printState,
				getValidationErrors,
				clearSelectedDeclaration,
				submitDeclaration,
				getDeclarationById,
				setLoadingDeclarationId,
				createAmendment,
				isDeclarationReadOnly,
				updateDeclarationInState,
			}}
		>
			<NotificationToast
				isOpen={toast.isOpen}
				title={toast.title}
				text={toast.text}
				variant={toast.variant}
				onClose={() => setToast({ isOpen: false })}
			/>
			{props.children}
		</DeclarationsContext.Provider>
	);
};

export default DeclarationsState;
