export const FILE_UPLOAD_LIMIT = "15Mb";
export const COMMODITY_CODES_URL = "https://www.trade-tariff.service.gov.uk/sections";

export const ITEM_QUERY_PARAM = "item";
export const PAGE_QUERY_PARAM = "page";
export const TAB_QUERY_PARAM = "tab";
export const ELEMENT_QUERY_PARAM = "element";
export const DECLARATION_ID_QUERY_PARAM = "declarationId";
export const FILE_ID_QUERY_PARAM = "fileId";

export const LS_THEME_KEY = "theme";
export const LS_LANGUAGE_KEY = "language";
export const LS_ACCESS_TOKEN_KEY = "access_token";
export const LS_REFRESH_TOKEN_KEY = "refresh_token";
export const LS_LOGIN_DATE_KEY = "login_date";
export const LS_LIST_SORT_KEY = 'list_sort';
export const LS_ID_TOKEN_KEY = "ID_TOKEN";
export const LS_SELECTED_WORKSPACE_KEY = "SELECTED_WORKSPACE";
export const LS_DISABLE_REQUEST_PERMISSION_KEY = "DISABLE_REQUEST_PERMISSION_MODAL";
export const REFRESH_TOKEN_VALIDITY_IN_DAYS = 30;

export const ALLOWED_FILE_FORMATS = ["application/pdf", "image/jpeg", "image/png", "text/plain"];

export const FILE_SIZE_LIMIT_IN_MB = 10;

export const DECLARATION_STATUSES = {
	INCOMPLETE: "incomplete",
	SUBMITTED: "submitted",
	ACCEPTED: "accepted",
	REJECTED: "rejected",
	PENDING_CANCEL: "pendingCancel",
	CANCELLED: "cancelled",
	CLEARED: "cleared",
};

export const STATUS_NAME_CODES = {
	39: "accepted",
};

export const SEARCH_TYPES = {
	CONTACTS: "contacts",
	GOODS_LOCATIONS: "locationOfGoods",
	COMMODITY_CODES: "commodityCodes",
};

export const SEARCH_TYPE_ELEMENTS = {
	CONTACTS: [
		{ name: "contactName", isRequired: true },
		{ name: "contactSurname" },
		{ name: "contactCountry" },
		{ name: "contactPostCode" },
		{ name: "contactEori" },
		{ name: "contactCity" },
		{ name: "contactAddressLine01" },
		{ name: "contactAddressLine02" },
		{ name: "contactAddressLine03" },
		{ name: "contactAddressLine04" },
		{ name: "contactPhone01" },
		{ name: "contactPhone02" },
		{ name: "contactPhone03" },
		{ name: "contactEmail" },
		{ name: "contactRole" },
		{ name: "description" },
	],
	COMMODITY_CODES: [
		{ name: "commodityCodeId", isRequired: true },
		{ name: "commodityCodeType", isRequired: true },
	],
	GOODS_LOCATIONS: [
		{ name: "locationOfGoodsId", isRequired: true },
		{ name: "locationOfGoodsType", isRequired: true },
		{ name: "locationOfGoodsCountry" },
		{ name: "locationOfGoodsAddress" },
		{ name: "locationOfGoodsCity" },
		{ name: "locationOfGoodsPostCode" },
		{ name: "contactRole", type: "text" },
		{ name: "qualifierOfIdentification" },
		{ name: "goodslocation" },
		{ name: "locationOfGoodsName", type: "text" },
	],
};
