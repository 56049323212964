import { useTranslation } from "react-i18next";
import ConfirmationModal from "./ConfirmationModal";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const UnsavedChangesModal = ({ hasUnsavedChanges }) => {
	const [isOpen, setIsOpen] = useState();
	const { t } = useTranslation();

	/** Location that the user attempted to navigate to but was blocked */
	const intendedLocation = useRef(null);
	const history = useHistory();

	const handlePrimaryActionClick = () => {
		if (!intendedLocation.current) {
			return;
		}

		setIsOpen(false);
		history.push(intendedLocation.current);
	};

	useEffect(() => {
		const unblock = history.block((location) => {
			if (hasUnsavedChanges && !intendedLocation.current) {
				setIsOpen(true);

				intendedLocation.current = location;

				return false;
			}

			intendedLocation.current = null;

			return true;
		});

		return () => {
			unblock();
		};
	}, [hasUnsavedChanges]);

	return (
		<ConfirmationModal
			isOpen={isOpen}
			onClose={() => {
				setIsOpen(false);
				intendedLocation.current = null;
			}}
			title={t("unsavedChangesWarningTitle")}
			description={t("unsavedChangesWarningDesc")}
			primaryActionLabel={t("leave")}
			onPrimaryActionClick={handlePrimaryActionClick}
			primaryActionStyle={{ width: 150 }}
		/>
	);
};

export default UnsavedChangesModal;
