import { useContext, useEffect, useState } from "react";
import NotificationDetailsModal from "../../../../components/layouts/declarations/NotificationDetailsModal/NotificationDetailsModal";
import NotificationItem from "../../../../components/common/NotificationItem/NotificationItem";
import DeclarationsContext from "../../../../context/declarations/DeclarationsContext";
import EmptyPageMessage from "../../../../components/common/EmptyPageMessage";
import { useTranslation } from "react-i18next";
import NotificationsContext from "../../../../context/notifications/NotificationsContext";

const Notifications = () => {
	const [isDisplayElementsModalOpen, setIsDisplayElementsModalOpen] = useState(false);
	const [selectedNotification, setSelectedNotification] = useState();
	const { state, setSelectedDeclaration } = useContext(DeclarationsContext);
	const { readNotifications } = useContext(NotificationsContext);
	const { t } = useTranslation();

	const getNotificationClickAction = (notification) => {
		if (
			(notification.elements && Object.keys(notification.elements)?.length) ||
			notification.errors?.length
		) {
			return () => {
				setIsDisplayElementsModalOpen(true);
				setSelectedNotification(notification);
			};
		}

		return null;
	};

	/** Mark unread notifications as read */
	useEffect(() => {
		const unreadNotifications = state.declaration.notifications.filter(
			(notif) => !notif.isRead && notif._id
		);

		if (!state.declaration?.notificationsRead && unreadNotifications.length) {
			(async () => {
				await readNotifications(unreadNotifications.map((notif) => notif._id));
			})();
		}

		setSelectedDeclaration({ ...state.declaration, notificationsRead: true });
	}, []);

	return (
		<>
			<NotificationDetailsModal
				open={isDisplayElementsModalOpen}
				notification={selectedNotification}
				onClose={() => setIsDisplayElementsModalOpen(false)}
			/>

			<div style={{ marginTop: 20 }}>
				{state.declaration.notifications.map((notification, index) => {
					const clickHandler = getNotificationClickAction(notification);

					return (
						<NotificationItem
							key={index}
							notification={notification}
							onClick={clickHandler}
						/>
					);
				})}

				{!state.declaration.notifications.length && (
					<EmptyPageMessage text={t("noDeclarationNotifications")} />
				)}
			</div>
		</>
	);
};

export default Notifications;
