import React, { useContext, useState, useEffect, useMemo } from "react";
import { createLocation, updateLocation } from "../../../../api/goodsLocations";
import elements from "../../../../constants/validation";
import DeclarationsContext from "../../../../context/declarations/DeclarationsContext";
import NotificationToast from "../../../common/NotificationToast";
import ThemeContext from "../../../../context/theme/ThemeContext";
import SectionActions from "./components/SectionActions";
import CustomSectionSearchButton from "../CustomSections/CustomSectionSearchButton";
import { SEARCH_TYPE_ELEMENTS } from "../../../../constants/GlobalConstants";
import ValidationInput from "../../../common/InputsSection/ValidationInput";
import WorkspacesContext from "../../../../context/workspaces/WorkspacesContext";
import { useTranslation } from "react-i18next";

const initialState = {
	locationOfGoodsId: "",
	locationOfGoodsCountry: "",
	locationOfGoodsCity: "",
	contactsCountryList: "",
	locationOfGoodsPostCode: "",
	qualifierOfIdentification: "",
	goodslocation: "",
	locationOfGoodsName: "",
};

const LocationOfGoods = () => {
	const { setElementValueInSection, state, setHasUnsavedChanges, isDeclarationReadOnly } =
		useContext(DeclarationsContext);
	const { selectedWorkspaceId } = useContext(WorkspacesContext);
	const [saveConfirmation, setSaveConfirmation] = useState(false);
	const [isUpdateLoading, setIsUpdateLoading] = useState(false);
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [inputValues, setInputValues] = useState(initialState);
	const { withTheme } = useContext(ThemeContext);
	const { t } = useTranslation();

	const readOnly = useMemo(() => isDeclarationReadOnly(), [state.declaration]);

	const handleSearchInputChange = (e) => {
		setSearchValue(e.target.value);
	};
	/**
	 * on the current user (after auth implementation) updating location of goods
	 */
	const handleUpdateExistingClick = async () => {
		if (!inputValues._id) {
			return;
		}

		setIsUpdateLoading(true);

		const { _id, ...data } = inputValues;

		await updateLocation(_id, data);

		setIsUpdateLoading(false);
	};
	/**
	 * on the current user (after auth implementation) adding a new location of goods
	 */
	const handleSaveClick = async () => {
		setIsSaveLoading(true);
		const { _id, ...data } = inputValues;
		const location = await createLocation({ ...data, workspace: selectedWorkspaceId });

		setInputValues({
			...inputValues,
			_id: location._id,
		});

		setSaveConfirmation(true);
		setIsSaveLoading(false);
	};
	/**
	 * on the current user (after auth implementation) (delete) clearing the location of goods
	 */
	const handleClearClick = () => {
		let updatedState = {};

		SEARCH_TYPE_ELEMENTS.GOODS_LOCATIONS.forEach((element) => {
			updatedState[element.name] = "";
			setElementValueInSection("locationOfGoods", element.name, "");
		});

		setInputValues(updatedState);
	};

	const handleChange = (name, value) => {
		let updatedState = {
			...inputValues,
			[name]: value,
		};
		setHasUnsavedChanges(true);

		setInputValues(updatedState);
		setElementValueInSection("locationOfGoods", name, value);
	};
	/**
	 * searching location of goods of an existing user by data prop
	 */
	const handleSearchSelection = (data) => {
		setIsSearchModalOpen(false);
		Object.keys(data).forEach((key) => {
			setElementValueInSection("locationOfGoods", key, data[key]);
		});
	};

	useEffect(() => {
		setInputValues(state.declaration.data.locationOfGoods || {});
	}, [state]);

	return (
		<div className={`${withTheme("lemon")} ${withTheme("inputs-section")}`}>
			<NotificationToast
				isOpen={saveConfirmation}
				onClose={() => setSaveConfirmation(false)}
				variant='success'
				text={t("locationSaveSuccess")}
			/>

			<div style={{ display: "flex", alignItems: "center", marginBottom: 20 }}>
				<div className={withTheme("inputs-section-title")}>{t('locationOfGoods')}</div>

				{!readOnly && (
					<CustomSectionSearchButton
						onSearchInputChange={handleSearchInputChange}
						searchValue={searchValue}
						setIsSearchModalOpen={setIsSearchModalOpen}
						isSearchModalOpen={isSearchModalOpen}
						sectionElements={SEARCH_TYPE_ELEMENTS.GOODS_LOCATIONS}
						onAddClick={handleSearchSelection}
						searchType="locationOfGoods"
					/>
				)}
			</div>

			<div style={{ display: "flex", flexWrap: "wrap", columnGap: 40, rowGap: 20 }}>
				{SEARCH_TYPE_ELEMENTS.GOODS_LOCATIONS.map((element, index) => (
					<Element
						element={element}
						inputValues={inputValues}
						onChange={handleChange}
						key={index}
						readOnly={readOnly}
					/>
				))}
			</div>

			<SectionActions
				isUpdateLoading={isUpdateLoading}
				isSaveLoading={isSaveLoading}
				inputValues={inputValues}
				onUpdateExistingClick={handleUpdateExistingClick}
				onClearClick={handleClearClick}
				onSaveClick={handleSaveClick}
			/>
		</div>
	);
};

const Element = ({ readOnly, element, inputValues, onChange }) => {
	const { state } = useContext(DeclarationsContext);

	const validationElement = useMemo(
		() => elements.find((tmpElement) => tmpElement.elementName === element.name),
		[element]
	);

	if (element === "goodslocation" && state?.declaration?.service !== "export") {
		return <></>;
	}

	return (
		<div style={{ width: "calc(50% - 40px)", flexGrow: 1 }}>
			<ValidationInput
				onChange={onChange}
				groupingElement={element}
				value={inputValues[element.name]}
				element={validationElement}
				readOnly={readOnly}
			/>
		</div>
	);
};

export default LocationOfGoods;
