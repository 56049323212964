import { useContext, useMemo } from "react";
import ThemeContext from "../../../../../../../../context/theme/ThemeContext";
import validationElements from "../../../../../../../../constants/validation";
import ValidationInput from "../../../../../../../common/InputsSection/ValidationInput";

const SearchItemElement = ({ elementName, value, error, onInputChange, isEditModeEnabled }) => {
	const { withTheme } = useContext(ThemeContext);

	const element = useMemo(
		() => validationElements.find((element) => element.elementName === elementName),
		[elementName]
	);

	if (!element) {
		return null;
	}

	if (isEditModeEnabled) {
		return (
			<div className={withTheme("item-element-container")}>
				<ValidationInput element={element} value={value || ""} error={error} onChange={onInputChange} />
			</div>
		);
	} else {
		return (
			<div className={withTheme("item-element-container")}>
				<div className={withTheme("item-element-name")}>{element.textDescription}</div>
				<div className={withTheme("item-element-value")}>{value || "none"}</div>
			</div>
		);
	}
};

export default SearchItemElement;
