import { useContext, useMemo, useState } from "react";
import { createCommodityCode, updateCommodityCode } from "../../../../api/commodity-codes";
import InputsSection from "../../../common/InputsSection/InputsSection";
import CustomSectionSearchButton from "../CustomSections/CustomSectionSearchButton";
import SectionActions from "./components/SectionActions";
import NotificationToast from "../../../common/NotificationToast";
import { SEARCH_TYPES, SEARCH_TYPE_ELEMENTS } from "../../../../constants/GlobalConstants";
import DeclarationsContext from "../../../../context/declarations/DeclarationsContext";
import WorkspacesContext from "../../../../context/workspaces/WorkspacesContext";
import { useTranslation } from "react-i18next";

const CommodityCodes = (props) => {
	const [isSaveConfirmToastOpen, setIsSaveConfirmToastOpen] = useState(false);
	const [inputValues, setInputValues] = useState({});
	const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const [isUpdateLoading, setIsUpdateLoading] = useState(false);
	const { selectedWorkspaceId } = useContext(WorkspacesContext);
	const { isDeclarationReadOnly, state } = useContext(DeclarationsContext);
	const { t } = useTranslation();
	const { itemIndex } = props;

	const readOnly = useMemo(() => isDeclarationReadOnly(), [state.declaration]);

	const handleUpdateExistingClick = async () => {
		if (!inputValues.id) return;
		setIsUpdateLoading(true);

		const { _id, ...data } = inputValues;
		try {
			await updateCommodityCode(_id, data);
		} catch (e) {
			console.log(e);
		}
		setIsUpdateLoading(false);
	};

	const handleSaveClick = async () => {
		setIsSaveLoading(true);

		try {
			const { _id, ...data } = inputValues;
			const code = await createCommodityCode({ ...data, workspace: selectedWorkspaceId });

			setInputValues({
				...inputValues,
				_id: code._id,
			});
			setIsSaveConfirmToastOpen(true);
			setIsSaveLoading(false);
		} catch (e) {
			console.log(e);
		}
	};

	const handleClearClick = () => {
		setInputValues({});
	};

	const handleSearchSelection = (data) => {
		setInputValues({ ...data });
		setIsSearchModalOpen(false);
	};

	return (
		<div>
			<NotificationToast
				isOpen={isSaveConfirmToastOpen}
				variant="success"
				onClose={() => setIsSaveConfirmToastOpen(false)}
				text={t("commodityCodeSaveSuccess")}
			/>

			<InputsSection
				title={t("commodityCodes")}
				section="commodityCodes"
				styleName="lemon"
				fullWidth={true}
				SectionActions={
					!readOnly && (
						<CustomSectionSearchButton
							searchType={SEARCH_TYPES.COMMODITY_CODES}
							onSearchInputChange={(e) => setSearchValue(e.target.value)}
							searchValue={searchValue}
							setIsSearchModalOpen={setIsSearchModalOpen}
							isSearchModalOpen={isSearchModalOpen}
							sectionElements={SEARCH_TYPE_ELEMENTS.COMMODITY_CODES}
							onAddClick={handleSearchSelection}
						/>
					)
				}
				elements={[
					{
						type: "multiline",
						elementName: "commodityCodes",
						inputValues,
						setInputValues,
						ActionsComponent: !readOnly && (
							<SectionActions
								hideUpdateExisting
								hideClearButton
								inputValues={inputValues}
								isUpdateLoading={isUpdateLoading}
								isSaveLoading={isSaveLoading}
								onUpdateExistingClick={handleUpdateExistingClick}
								onClearClick={handleClearClick}
								onSaveClick={handleSaveClick}
							/>
						),
					},
				]}
				itemIndex={itemIndex}
			/>
		</div>
	);
};

export default CommodityCodes;
