import { startCase } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import TableDisplayElement from "./TableDisplayElement";
import Modal from "../../../modals/Modal";
import NotificationError from "./NotificationError";
import { useMemo, useState } from "react";
import Tabs from "../../../common/Tabs/Tabs";

const NotificationDetailsModal = (props) => {
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const { open, notification, onClose } = props;
	const { t } = useTranslation();

	const tabs = useMemo(() => {
		let tabs = [];

		if (notification?.errors?.length) {
			tabs.push(t("errors"));
		}

		if (notification?.elements && Object.keys(notification.elements).length) {
			tabs.push(t("data"));
		}

		return tabs;
	}, [notification]);

	if (!notification) {
		return null;
	}

	return (
		<Modal isOpen={open} onClose={onClose} label={t("notificationElements")} style={{ width: 560 }}>
			<Tabs
				tabs={tabs}
				selectedTabIndex={selectedTabIndex}
				onChange={(index) => {
					setSelectedTabIndex(index);
				}}
			/>

			{selectedTabIndex === 0 &&
				Boolean(notification.errors?.length) &&
				notification.errors.map((error, index) => (
					<NotificationError error={error} onViewClick={onClose} key={index} />
				))}

			{(!Boolean(notification.errors?.length) || selectedTabIndex === 1) &&
				Object.keys(notification.elements).map((key) => {
					const element = notification.elements[key];

					return (
						<>
							<div style={{ fontSize: 14, fontWeight: 700 }}>
								{startCase(key)}
							</div>
							{Array.isArray(element) ? (
								<TableDisplayElement element={element} />
							) : (
								<div style={{ fontSize: 14 }}>
									{moment(element).isValid()
										? moment(element).format(
												"DD/MM/YY - hh:mm A"
										  )
										: element}
								</div>
							)}
						</>
					);
				})}
		</Modal>
	);
};

export default NotificationDetailsModal;
