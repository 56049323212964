import React, { useContext, useEffect, useMemo, useState } from "react";
import DeclarationListItem from "./ListItem/ListItem";
import { removeQueryParam, updateQueryParam } from "../../../../router/utils";
import { useTranslation } from "react-i18next";
import DeclarationsContext from "../../../../context/declarations/DeclarationsContext";
import BasicSearch from "./BasicSearch";
import { PiNoteFill } from "react-icons/pi";
import {
	DECLARATION_ID_QUERY_PARAM,
	ELEMENT_QUERY_PARAM,
	ITEM_QUERY_PARAM,
	LS_LIST_SORT_KEY,
	PAGE_QUERY_PARAM,
	TAB_QUERY_PARAM,
} from "../../../../constants/GlobalConstants";
import Drawer from "../../../navigation/Drawer";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const DeclarationsList = (props) => {
	const [searchValue, setSearchValue] = useState("");
	const [filters, setFilters] = useState([]);
	const [filteredDeclarations, setFilteredDeclarations] = useState([]);
	const [sort, setSort] = useState(localStorage.getItem(LS_LIST_SORT_KEY) || "oldestFirst");
	const history = useHistory();
	const { t } = useTranslation();
	const { state } = useContext(DeclarationsContext);
	const { declarationsLoading, isExpanded, setIsExpanded, onAddClick } = props;

	const declarations = useMemo(() => {
		if (sort === "oldestFirst") {
			return filteredDeclarations;
		} else {
			return filteredDeclarations.slice().reverse();
		}
	}, [filteredDeclarations, sort]);

	const handleSortChange = (sort) => {
		setSort(sort);
		
		localStorage.setItem(LS_LIST_SORT_KEY, sort);
	};

	// Load the selected declaration into the forms
	const handleDeclarationClick = (declaration) => {
		if (state.hasUnsavedChanges) {
			// Triggers the UnsavedChangesModal
			history.push({
				...history.location,
				search: `${DECLARATION_ID_QUERY_PARAM}=${declaration._id}`,
			});
		} else {
			updateQueryParam(DECLARATION_ID_QUERY_PARAM, declaration._id);
			updateQueryParam(PAGE_QUERY_PARAM, 0);
			updateQueryParam(TAB_QUERY_PARAM, 0);
			removeQueryParam(ELEMENT_QUERY_PARAM);
			removeQueryParam(ITEM_QUERY_PARAM);
		}
	};

	useEffect(() => {
		if (state.declarations) {
			setFilteredDeclarations(state.declarations);
			setSearchValue("");
		}
	}, [state.declarations]);

	return (
		<>
			<Drawer
				isExpanded={isExpanded}
				setIsExpanded={setIsExpanded}
				CollapsedIcon={PiNoteFill}
				sort={sort}
				onSortChange={handleSortChange}
				onAddClick={onAddClick}
			>
				<BasicSearch
					filters={filters}
					declarations={state.declarations}
					searchValue={searchValue}
					setFilteredDeclarations={setFilteredDeclarations}
					setFilters={setFilters}
					setSearchValue={setSearchValue}
				/>

				{searchValue && declarations.length === 0 && (
					<div style={{ fontSize: 12, padding: 24 }}>{t("noResultsFound")}</div>
				)}

				{declarationsLoading && <Spinner style={{ marginTop: 32 }} animation="border" />}

				<div style={{ marginTop: 8 }}>
					{declarations.map((declaration, index) => (
						<DeclarationListItem
							key={index}
							index={
								declaration.index ??
								(sort === "oldestFirst"
									? index
									: declarations.length - index - 1)
							}
							onClick={handleDeclarationClick}
							declaration={declaration}
						/>
					))}
				</div>
			</Drawer>
		</>
	);
};

export default DeclarationsList;
